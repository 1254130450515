import React from "react";
import "./footer.css"
const Footer = ({ t }) => {
    return (
        <>
            <footer className="footer">
                <div className="footer-box1">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5907.703909467091!2d59.61137079573354!3d42.46668291122005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41dd9bf8709c4ef1%3A0xfd99954c5069c41e!2sTourist%20Centre!5e1!3m2!1sru!2s!4v1720685989068!5m2!1sru!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="footer-box2">
                    <div className="container">
                        <div className="footer-box2-flex">
                            <div className="box2-line1">
                                <div className="box2-line1-1">
                                    <div className="line1-li">
                                        <span className="logo-i-li">
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                        <span className="line1-li-text">
                                            <h1>{t("footer-address")}:</h1>
                                            <h1>{t("address")}</h1>
                                            <h1>22</h1>
                                        </span>
                                    </div>
                                    <div className="line1-li">
                                        <span className="logo-i-li">
                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                        </span>
                                        <span className="line1-li-text">
                                            <h1>{t("phoneNumber")}:</h1>
                                            <a className="link" href="tel:+998781138606">+998 78 113 86 06</a>
                                            <a className="link" href="tel:+998509004020">+998 93 424 24 84</a>
                                            <h1></h1>
                                            <h1></h1>
                                        </span>
                                    </div>
                                    <div className="line1-li">
                                        <span className="logo-i-li">
                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                        </span>
                                        <span className="line1-li-text">
                                            <h1>{t("email")}:</h1>
                                            <h1>araldiscovery@gmail.com</h1>
                                        </span>
                                    </div>
                                </div>
                                <div className="line1-soc">
                                    <a href="https://t.me/+998934242484" target="_blank">
                                        <i class="fa fa-telegram" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.instagram.com/aral_sea_discovery/" target="_blank">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.facebook.com/tazabay.uteuliev/" target="_blank">
                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="box2-line2">
                                <span>
                                    <i class="fa fa-creative-commons" aria-hidden="true"></i>
                                    {t("PravaZach")}.
                                </span>
                                <span>Aral Sea Discovery - 2024</span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;